import LruCache from "lru-cache";
import axios from "axios";
import {getToken, loadData, saveData, ServerStatistics} from "bbw-common";
import {Store} from "vuex";
/**
 * 创造axios拦截器
 */
export const createAxiosInterceptors = ( store:Store<any> ) => {
    const maxReqNumber = __DEV__ ? 120 : 120 ;
    // 创建缓存, 1分钟内请求超过次数则记录到统计接口，防止恶意请求
    const cache = new LruCache<string, number> ( {
        maxAge: 1000 * 60 * 1
    } ) ;
    // 请求拦截
    axios.interceptors.request.use ( config => {
        const { url, params } = config ;
        const uid = store.state.UserInfo.id ;
        const reg = /\/\/pinlite(-test)?\.bigbigwork\.com/;
        if ( !reg.test ( url ) ) return config ;
        const fullUrl = `${url}:${new URLSearchParams ( params ).toString ()}` ;
        // 记录请求次数
        const reqNumber = cache.itemCount ;
        cache.set ( `${new Date ().getTime ()}:${fullUrl}`, `1` ) ;
        const hasStat = loadData ( `api-frequency`, uid ) ;
        if ( reqNumber > maxReqNumber && !hasStat ) {
            saveData ( `api-frequency`, uid, true, { expires: 1, unit: `hour` } ) ;
            // 超过则记录到统计接口
            console.log ( `请求次数超过次数`, reqNumber, cache ) ;
            const keys = cache.keys () ;
            const statInfo = {
                token: getToken (),
                reqList: keys,
                uid: uid,
                routerHistory: window.routerHistory,
                screen: {
                    availHeight: window.screen.availHeight,
                    availWidth: window.screen.availWidth,
                    height: window.screen.height,
                    width: window.screen.width,
                    colorDepth: window.screen.colorDepth,
                    pixelDepth: window.screen.pixelDepth,
                    orientation: {
                        angle: window.screen.orientation?.angle,
                        type: window.screen.orientation?.type
                    }
                },
                window: {
                    innerHeight: window.innerHeight,
                    innerWidth: window.innerWidth
                },
                devicePixelRatio: window.devicePixelRatio
            }
            axios.post (
                ServerStatistics + `/pinlite/plJson/stats`,
                JSON.stringify ( statInfo ),
                {
                    headers: {
                        "Content-Type": `application/json`
                    }
                }) ;
        }
        return config ;
    } ) ;
}